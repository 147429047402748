import React, { Dispatch, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../../../../../ui/button/button';
import { CollapseAll, ExpandAll } from '../../../../../ui/icons/icons';
import { TextInput } from '../../../../../ui/input/text_input/text_input';
import { createAutoOutstanding, createAutoSuitability } from '../../../../../utils/api_service/JobOpenings';
import { AutoEvaluation, showAllTypes } from '../../../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import { RangeSelectInput } from '../../ui/RangeSelectComponent';
import ListFields from './ListFieldComponents';

type JobDescriptionTabProps = {
  autoEvaluation: AutoEvaluation,
  setAutoEvaluation: Dispatch<React.SetStateAction<AutoEvaluation>>,
  evaluationType: 'Auto-Suitability' | 'Auto-Outstanding'
  importAutoSuitability?: () => void,
}

const rangeSelectOptions: SelectOptions[] = [
  {
    label: 'Greater Than',
    value: 'Greater Than',
  },
  {
    label: 'Lesser Than',
    value: 'Lesser Than',
  },
];

export const AutoEvaluationTab = ({
  autoEvaluation,
  setAutoEvaluation,
  evaluationType,
  importAutoSuitability,
}: JobDescriptionTabProps) => {
  const [showAll, setShowAll] = useState<showAllTypes>('showAll');

  const saveAutoEvaluation = async () => {
    try {
      const createAutoEvaluationResponse = evaluationType === 'Auto-Suitability' ?
        await createAutoSuitability(autoEvaluation) : await createAutoOutstanding(autoEvaluation);

      if (createAutoEvaluationResponse.ok) {
        createAutoEvaluationResponse.json().then(data => {
          setAutoEvaluation(data);
          toast.success(`${evaluationType} Saved Successfully`, { position: 'bottom-center' });
        });
      } else {
        toast.error(`${evaluationType} Save Failed`, { position: 'bottom-center' });
      }
    } catch (error) {
      toast.error(`${evaluationType} Save Failed`, { position: 'bottom-center' });
    }
  };

  const toggleShowAll = () => {
    let newShowAllState: showAllTypes = 'showAll';
    if (showAll === 'showAll') {
      newShowAllState = 'collapseAll';
    } else if (showAll === 'collapseAll') {
      newShowAllState = 'showAll';
    } else {
      newShowAllState = 'showAll';
    }
    setShowAll(newShowAllState);
  };

  return (
    <div
        className='flex flex-col h-full relative'
    >
      { evaluationType === 'Auto-Outstanding' &&
      <div
          className='absolute right-0 top-0'
      >
        <Button
            onClick={importAutoSuitability && importAutoSuitability || (() => {})}
            variant='primary'
            size='medium'
        >
          Import from suitability
        </Button>
      </div>
      }
      <button
          onClick={toggleShowAll}
          className='w-7 h-7 mt-6 absolute'
      >
        { showAll === 'collapseAll' || showAll === 'random' ? <ExpandAll/> : <CollapseAll/> }
      </button>
      <div className='flex gap-3 mb-5 pl-9'>
        <div className='w-1/3'>
          <div className='text-gray-800 py-1 text-sm'>
            Job Location
          </div>
          <TextInput
              name='Job Location'
              value={autoEvaluation.jobLocation}
              type='text'
              onChange={(event) => {
                setAutoEvaluation({ ...autoEvaluation, jobLocation: event.target.value });
              }}
          />
        </div>
        <RangeSelectInput
            label='Age'
            selectOptions={rangeSelectOptions}
            onSelectChange={(selected: any) => setAutoEvaluation({
              ...autoEvaluation,
              age: {
                ...autoEvaluation.age,
                operator: selected.value,
              },
            })}
            selectValue={rangeSelectOptions.find(option => option.value === autoEvaluation.age.operator) || rangeSelectOptions[0]}
            onTextChange={(event) => {
              setAutoEvaluation({
                ...autoEvaluation,
                age: { ...autoEvaluation.age, value: parseInt(event.target.value) },
              });
            }}
            textValue={autoEvaluation.age.value.toString()}
            placeholder='Years'
            min='18'
            max='65'
        />
      </div>
      <div
          className='mb-5 pl-9'
      >
        <RangeSelectInput
            label='Work Experience'
            selectOptions={rangeSelectOptions}
            onSelectChange={(selected: any) => setAutoEvaluation({
              ...autoEvaluation,
              workExperience: {
                ...autoEvaluation.workExperience,
                operator: selected.value,
              },
            })}
            selectValue={rangeSelectOptions.find(option => option.value === autoEvaluation.workExperience.operator) || rangeSelectOptions[0]}
            onTextChange={(event) => {
              setAutoEvaluation({
                ...autoEvaluation,
                workExperience: { ...autoEvaluation.workExperience, value: parseInt(event.target.value) },
              });
            }}
            textValue={autoEvaluation.workExperience.value.toString()}
            placeholder='Years'
            min='0'
            max='50'
        />
      </div>
      <div className='mb-16'>
        <ListFields
            showAll={showAll}
            setShowAll={setShowAll}
            autoEvaluation={autoEvaluation}
            setAutoEvaluation={setAutoEvaluation}
        />
      </div>
      <div
          className='absolute right-0 bottom-0'
      >
        <Button
            onClick={saveAutoEvaluation}
            variant='primary'
            size='medium'
            disabled={autoEvaluation.jobLocation === '' || autoEvaluation.age.value === 0
                  || autoEvaluation.educations === undefined || autoEvaluation.certifications === undefined
                  || autoEvaluation.school === undefined || autoEvaluation.skills === undefined
                  || autoEvaluation.keywords === undefined}
        >
          Save { evaluationType }
        </Button>
      </div>
    </div>
  );
};
