import { ContentState, EditorState, Modifier, SelectionState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button } from '../../../../../../../../ui/button/button';

interface Props {
  htmlTemplate: string;
  validVariables: { name: string, variable: string }[];
  saveEmailTemplate: (emailTemplate: string) => void;
}

const EmailTemplateEditor: React.FC<Props> = ({
  htmlTemplate,
  validVariables,
  saveEmailTemplate,
}) => {
  const [editorState, setEditorState] = useState<EditorState>(() => {
    if (htmlTemplate) {
      const blocksFromHTML = convertFromHTML(htmlTemplate);
      let newContentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

      const regex = /{{([^}]+?)}}/g;

      newContentState.getBlockMap().forEach((block) => {
        if (block) {
          const blockText = block.getText();
          let match;
          while ((match = regex.exec(blockText)) !== null) {
            const variable = match[1];
            if (validVariables.some(item => item.variable === variable)) {
              const start = match.index;
              const end = regex.lastIndex;
              const selection = SelectionState.createEmpty(block.getKey()).merge({
                anchorOffset: start,
                focusOffset: end,
              });

              newContentState = Modifier.removeRange(newContentState, selection, 'backward');

              const insertionPoint = SelectionState.createEmpty(block.getKey()).merge({
                anchorOffset: start,
                focusOffset: start,
              });

              const contentStateWithEntity = newContentState.createEntity('VARIABLE', 'IMMUTABLE', { variable });
              const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
              newContentState = Modifier.insertText(contentStateWithEntity, insertionPoint, `{{${variable}}}`, undefined, entityKey);
            }
          }
        }
      });

      return EditorState.createWithContent(newContentState);
    }
    return EditorState.createEmpty();
  });

  const handleInsertVariable = (variable: string) => {
    if (validVariables.some(item => item.variable === variable)) {
      const selection = editorState.getSelection();
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity('VARIABLE', 'IMMUTABLE', { variable });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newContentState = Modifier.insertText(contentStateWithEntity, selection, `{{${variable}}}`, undefined, entityKey);
      const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
      setEditorState(EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter()));
    }
  };

  const handleKeyCommand = (command: string) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const blockWithSelection = contentState.getBlockForKey(startKey);

    const entityKey = blockWithSelection.getEntityAt(startOffset - 1);
    if (entityKey) {
      const entity = contentState.getEntity(entityKey);
      if (entity.getType() === 'VARIABLE') {
        if (command === 'backspace') {
          const variableLength = entity.getData().variable.length;
          if (startOffset >= variableLength + 2) {
            const newSelection = new SelectionState({
              anchorKey: startKey,
              anchorOffset: startOffset - variableLength - 2,
              focusKey: startKey,
              focusOffset: startOffset,
            });
            const newContentState = Modifier.removeRange(contentState, newSelection, 'backward');
            const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');
            setEditorState(EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter()));
            return 'handled';
          }
        }
      }
    }

    return 'not-handled';
  };

  const exportToHTML = () => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    saveEmailTemplate(html);
  };

  return (
    <>
      <div className='flex flex-row'>
        <div className='w-10/12'>
          <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              handleKeyCommand={handleKeyCommand}
          />
        </div>
        <div className='flex flex-col w-2/10'>
          { validVariables.map((variable) => (
            <Button
                key={variable.variable}
                variant='primary'
                onClick={() => handleInsertVariable(variable.variable)}
                size='medium'
            >
              { variable.name }
            </Button>
          )) }
        </div>
      </div>
      <Button
          variant='success'
          size='medium'
          onClick={exportToHTML}
      >
        Save
      </Button>
    </>

  );
};

export default EmailTemplateEditor;
