import { createMultiPartRequest, createRequest } from '../helpers/api_utils';
import {
  AutoAssessment,
  AutoEvaluation,
  JobDescription,
  JobOpeningRequest,
  JobOpeningSkill,
  JobRequisition,
  jobOpeningTypes,
} from '../types/JobOpenings';
import { apiUrl } from './api_url';

const apiEndpoint = apiUrl + '/job-openings';

export async function createJobOpening(jobOpeningTypesList: jobOpeningTypes[]) {
  let body = {
    jobOpeningElements: jobOpeningTypesList,
  };
  return fetch(createRequest(apiEndpoint, 'POST', 'application/json', true, body));
}

export async function getJobOpening(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}`, 'GET', 'application/json', true));
}

export async function createJobRequisition(jobRequisition: JobRequisition) {
  const { id, ...body } = jobRequisition;
  return fetch(createRequest(apiEndpoint + '/job-requisitions', 'POST', 'application/json', true, body));
}

export async function createJobDescription(jobDescription: JobDescription) {
  const body = {
    jobTitle: jobDescription.jobTitle,
    jobOpeningId: jobDescription.jobOpeningId,
    jobReferenceNumber: jobDescription.jobReferenceNumber,
    jobLocation: jobDescription.jobLocation,
    workType: jobDescription.workType,
    responsibilities: jobDescription.responsibilities,
    educations: jobDescription.educations,
    certifications: jobDescription.certifications,
    mandatorySkills: jobDescription.mandatorySkills,
    desirableSkills: jobDescription.desirableSkills,
    minimumWorkExperience: jobDescription.minimumWorkExperience,
  };
  return fetch(createRequest(apiEndpoint + '/job-descriptions', 'POST', 'application/json', true, body));
}

export async function getJobDescription(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}/job-description`, 'GET', 'application/json', true));
}

export async function getJobOpeningDetails(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}/details`, 'GET', 'application/json', true));
}

export async function getJobApplicants(id: string, search: string = '', page: number = 1, limit: number = 10, status: string = '') {
  const queryParams = new URLSearchParams({
    search: search,
    page: page.toString(),
    limit: limit.toString(),
    status: status,
  });

  const url = `${apiEndpoint}/${id}/applicants?${queryParams.toString()}`;

  return fetch(createRequest(url, 'GET', 'application/json', true));
}

export async function createAutoSuitability(autoSuitability: AutoEvaluation) {
  const body = { ...autoSuitability, id: undefined };
  return fetch(createRequest(apiEndpoint + '/auto-suitability', 'POST', 'application/json', true, body));
}

export async function createAutoOutstanding(autoEvaluation: AutoEvaluation) {
  const body = { ...autoEvaluation, id: undefined };
  return fetch(createRequest(apiEndpoint + '/auto-outstanding', 'POST', 'application/json', true, body));
}

export async function createAutoAssessment(autoAssessment: AutoAssessment) {
  const { id, ...body } = autoAssessment;
  return fetch(createRequest(apiEndpoint + '/auto-assessments', 'POST', 'application/json', true, body));
}

export async function getAutoAssessment(id: string) {
  return fetch(createRequest(apiEndpoint + `/${id}/auto-assessments`, 'GET', 'application/json', true));
}

export async function createAutoBuildQuestions(autoAssessmentId: string, jobTitle: string) {
  const body = {
    autoAssessmentId: autoAssessmentId,
    jobTitle: jobTitle,
  };
  return fetch(createRequest(apiEndpoint + '/auto-build-questions', 'POST', 'application/json', true, body));
}

export async function setAutoAssessmentSkills(jobOpeningId: string, skills: JobOpeningSkill[]) {
  const body = { jobOpeningId: jobOpeningId, skills: skills };
  return fetch(createRequest(apiEndpoint + '/auto-assessments/skills', 'POST', 'application/json', true, body));
}

export async function updateAutoAssessmentEmail(body: { jobOpeningId: string, emailTemplate: string }) {
  return fetch(createRequest(apiEndpoint + '/auto-assessments/email', 'POST', 'application/json', true, body));
}

export async function uploadFileJobDescription(jobOpeningId: string, file?: File, transcript?: string) {
  let formData = new FormData();
  if (file) {
    formData.append('file', file);
  } else if (transcript) {
    formData.append('transcript', transcript);
  }
  return fetch(createMultiPartRequest(apiEndpoint + `/${jobOpeningId}/job-description/file`, 'POST', true, formData));

}

export async function getJobOpeningList(body: JobOpeningRequest, page: number = 1, limit: number = 10) {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
  });

  return fetch(createRequest(apiEndpoint + `/view?${queryParams.toString()}`, 'POST', 'application/json', true, body));
}
