import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateAutoAssessmentEmail } from '../../../../../../../utils/api_service/JobOpenings';
import EmailTemplateEditor from './EmailTemplateEditor';

const htmlTemplate = `
<p>Hi {{applicant_name}},&nbsp;</p>
<p>You have been invited to participate in an online assessment by {{company_name}} for the {{job_title_name}} position.&nbsp;</p>
<p>Please complete the assessment by {{end_of_assessment}}</p>
<p>Kindly note that the deadline is in Coordinated Universal Time (UTC). Please ensure that you take the time zone difference into account when submitting your response.&nbsp;</p>
<ol> 
   <li>Please open the email in a laptop. The assessment is not optimised on phones.&nbsp;</li>  
   <li>You will need to enable your Webcam and Microphone on your Web Browser.&nbsp;</li> 
   <li>Questions will appear on your screen, please press the Start Recording button and then speak your answer&nbsp;</li> 
   <li>Press Stop Recording when finished and then click the Submit button&nbsp;</li> 
   <li>The Assessment is time limited – so please answer in an timely manner.&nbsp;</li>
   <li>There is a Practice Assessment which can be taken to get familiar with the system</li>
</ol>
<p>We have created an account for you. To access the assessment, please click on the button below: Start Assessment If you have any questions or concerns, please don't hesitate to contact us at {{support_email}}.</p>
<p>Thank you for your interest in {{company_name}}!</p>
`;

const variables = [
  { name: 'Applicant Name', variable: 'applicant_name' },
  { name: 'Company Name', variable: 'company_name' },
  { name: 'Job Title Name', variable: 'job_title_name' },
  { name: 'End of Assessment', variable: 'end_of_assessment' },
  { name: 'Support Email', variable: 'support_email' },
  { name: 'Support Phone', variable: 'support_phone' },
  { name: 'Company Logo', variable: 'company_logo' },
];

type InvitationTemplateProps = {
  emailTemplate: string | undefined
  updateEmailTemplateState: (updateTemplate: string) => void,
}

export const InvitationTemplate = ({
  emailTemplate,
  updateEmailTemplateState,
}: InvitationTemplateProps) => {
  const { id } = useParams<{ id: string }>();
  const [editedTemplate, setEditedTemplate] = useState(emailTemplate || htmlTemplate);

  const saveEmailTemplate = async (emailTemplate: string) => {
    try {
      const updateEmailResponse = await updateAutoAssessmentEmail({ jobOpeningId: id, emailTemplate: emailTemplate });
      if (updateEmailResponse.ok) {
        setEditedTemplate(emailTemplate);
        updateEmailTemplateState(emailTemplate);
      } else {
        throw new Error('Error updating email template');
      }
    } catch (error) {
      toast.error((error as Error).message, { position: 'bottom-center' });
    }
  };

  return (
    <div
        className='w-full p-5'
    >
      <EmailTemplateEditor
          htmlTemplate={editedTemplate}
          validVariables={variables}
          saveEmailTemplate={saveEmailTemplate}
      />
    </div>
  );
};
