import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import autoAssessmentSvg from '../../../../assets/images/graphics/auto-assessment-illustration.svg';
import autoOutstandingSvg from '../../../../assets/images/graphics/auto-outstanding.svg';
import autoSuitabilitySvg from '../../../../assets/images/graphics/auto-suitability.svg';
import CVIngestionSvg from '../../../../assets/images/graphics/cv-ingestion.svg';
import jobDescriptionSvg from '../../../../assets/images/graphics/job-description.svg';
import jobRequisitionSvg from '../../../../assets/images/graphics/job-requisition.svg';
import { getEmployeeList } from '../../../../utils/api_service/employees';
import { searchJobTitleList } from '../../../../utils/api_service/job_title';
import { getJobOpeningDetails } from '../../../../utils/api_service/JobOpenings';
import { JobOpeningsContext } from '../../../../utils/contexts/JobOpening';
import { EmployeeUser, JobTitle } from '../../../../utils/types/admin_types';
import {
  AutoAssessment,
  AutoEvaluation,
  JobDescription,
  JobOpeningDetail,
  JobRequisition,
} from '../../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../../utils/types/react_select_types';
import ButtonColumn, { SingleButton } from '../ui/ButtonColumn';
import LoadingSpinner from '../ui/LoaderSpinner';
import { AutoAssessmentTab } from './AutoAssessmentTab';
import { AutoEvaluationTab } from './AutoEvaluationTab';
import CVIngestionTab from './CVIngestion';
import JobDescriptionTab from './JobDescription';
import JobRequisitionTab from './JobRequisition';

const transformJobRequisitionData = (originalData: JobRequisition): any => {
  originalData.deadline = new Date(originalData.deadline as number * 1000).toISOString().split('T')[0];
  const hiringManagerList: SelectOptions[] = originalData.hiringManager.map(item => ({
    label: typeof item !== 'string' ? `${item.user.firstName} ${item.user.lastName}` : item,
    value: typeof item !== 'string' ? item.id : item,
  }));
  const internalRecruiterList: SelectOptions[] = originalData.internalRecruiter.map(item => ({
    label: typeof item !== 'string' ? `${item.user.firstName} ${item.user.lastName}` : item,
    value: typeof item !== 'string' ? item.id : item,
  }));
  originalData.hiringManager = originalData.hiringManager.map(item => typeof item !== 'string' ? item.id : item);
  originalData.internalRecruiter = originalData.internalRecruiter.map(item => typeof item !== 'string' ? item.id : item);

  return { originalData, hiringManagerList, internalRecruiterList };
};

const JobOpeningDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { jobOpeningElements } = useContext(JobOpeningsContext);
  const [currentTab, setCurrentTab] = useState<string>('Job Requisition');
  const [jobTitleList, setJobTitleList] = useState<JobTitle[]>([]);
  const [hiringManager, setHiringManager] = useState<SelectOptions[]>();
  const [internalRecruiter, setInternalRecruiter] = useState<SelectOptions[]>();
  const [defaultEmployeeUserList, setDefaultEmployeeUserList] = useState<EmployeeUser[]>([]);
  const [jobRequisitionLoading, setJobRequisitionLoading] = useState<boolean>(false);
  const [employeeLoading, setEmployeeLoading] = useState<boolean>(false);
  const [isJobOpeningLoaded, setIsJobOpeningLoaded] = useState<boolean>(false);
  const [jobTitle, setJobTitle] = useState<SelectOptions | undefined>();
  const [jobRequisition, setJobRequisition] = useState<JobRequisition>(
    {
      id: '',
      jobRequisitionType: 'New Role',
      applicantScope: ['External'],
      jobOpeningId: id,
      hiringManager: [],
      deadline: 0,
      internalRecruiter: [],
      headCount: 1,
    },
  );
  const [jobDescription, setJobDescription] = useState<JobDescription>({
    id: '',
    jobOpeningId: id,
    jobReferenceNumber: '',
    jobReferenceNumberJD: '',
    minimumWorkExperience: 0,
    jobLocation: '',
    responsibilities: [],
    educations: [],
    certifications: [],
    mandatorySkills: [],
    desirableSkills: [],
    transcript: '',
    completed: false,
  });
  const [autoAssessment, setAutoAssessment] = useState<AutoAssessment>({
    id: '',
    jobOpeningId: id,
    assessmentId: '',
  });
  const [autoSuitability, setAutoSuitability] = useState<AutoEvaluation>({
    id: '',
    jobOpeningId: id,
    jobLocation: '',
    age: {
      value: 18,
      operator: 'Greater Than',
    },
    workExperience: {
      value: 0,
      operator: 'Greater Than',
    },
    educations: [],
    certifications: [],
    skills: [],
    school: [],
    keywords: [],
  });
  const [autoOutstanding, setAutoOutstanding] = useState<AutoEvaluation>({
    id: '',
    jobOpeningId: id,
    jobLocation: '',
    age: {
      value: 18,
      operator: 'Greater Than',
    },
    workExperience: {
      value: 0,
      operator: 'Greater Than',
    },
    educations: [],
    certifications: [],
    skills: [],
    school: [],
    keywords: [],
  });

  const buttonsList: SingleButton[] = useMemo(() => ([
    {
      label: 'Job Requisition',
      disabled: false,
      svg: jobRequisitionSvg,
    },
    {
      label: 'Job Description',
      disabled: false,
      svg: jobDescriptionSvg,
    },
    {
      label: 'CV Ingestion',
      disabled: !jobOpeningElements.includes('Applicant Analysis'),
      svg: CVIngestionSvg,
    },
    {
      label: 'Auto Suitability',
      disabled: !jobOpeningElements.includes('Auto-Suitability'),
      svg: autoSuitabilitySvg,
    },
    {
      label: 'Auto Outstanding',
      disabled: !jobOpeningElements.includes('Auto-Outstanding'),
      svg: autoOutstandingSvg,
    },
    {
      label: 'Auto Assessments',
      disabled: !jobOpeningElements.includes('Auto-Assessments'),
      svg: autoAssessmentSvg,
    },
  ]), [jobOpeningElements]);

  useEffect(() => {
    const fetchJobTitles = async () => {
      try {
        const response = await searchJobTitleList('');
        setJobTitleList(response);
      } catch (error) {
        toast.error('Error Loading Job Titles', { position: 'bottom-center' });
      }
    };
    fetchJobTitles();
  }, []);

  useEffect(() => {
    setJobRequisitionLoading(true);
    getJobOpeningDetails(id).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Error Fetching Job Requisition');
      }
    }).then((originalData : JobOpeningDetail) => {
      if (originalData.jobRequisition) {
        const transformedData = transformJobRequisitionData(originalData.jobRequisition);
        setInternalRecruiter(transformedData.internalRecruiterList);
        setHiringManager(transformedData.hiringManagerList);
        setJobRequisition(transformedData.originalData);
      }
      if (originalData.jobDescription) {
        let jobTitleValue = originalData.jobDescription.jobTitle;
        if (jobTitleValue) {
          const jobTitleOption: SelectOptions = {
            label: typeof jobTitleValue !== 'string' ? jobTitleValue?.jobTitle : jobTitleValue,
            value: typeof jobTitleValue !== 'string' ? jobTitleValue?.id : jobTitleValue,
          };
          setJobTitle(jobTitleOption);
          originalData.jobDescription.jobTitle = jobTitleOption.value;
        }
        setJobDescription(originalData.jobDescription);
      }
      if (originalData.autoSuitability) {
        setAutoSuitability(originalData.autoSuitability);
      }
      if (originalData.autoOutstanding) {
        setAutoOutstanding(originalData.autoOutstanding);
      }
      if (originalData.autoAssessment) {
        setAutoAssessment(originalData.autoAssessment);
      }
    }).catch((error) => {
      if (error.message === 'Unexpected error') {
        toast.error('Error Loading Job Requisition', { position: 'bottom-center' });
      }
    }).finally(() => {
      setJobRequisitionLoading(false);
      setIsJobOpeningLoaded(true);
    });
  }, [id]);

  useEffect(() => {
    setEmployeeLoading(true);
    getEmployeeList('', 1, 8)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          response.json().then(errorData => {
            toast.error(errorData.error, { position: 'bottom-center' });
          });
        }
      })
      .then(data => {
        setDefaultEmployeeUserList(data.employee);
      })
      .catch(() => {
        toast.error('Error fetching employees', { position: 'bottom-center' });
      }).finally(() => {
        setEmployeeLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isJobOpeningLoaded && autoSuitability.id.length === 0) {
      setAutoSuitability({
        ...autoSuitability,
        jobOpeningId: id,
        jobLocation: jobDescription.jobLocation || '',
        workExperience: {
          value: jobDescription.minimumWorkExperience || 0,
          operator: 'Greater Than',
        },
        educations: jobDescription.educations && jobDescription.educations.map(education => {
          return { value: education, relationToNext: 'AND' };
        }) || [],
        certifications: jobDescription.certifications && jobDescription.certifications.map(certification => {
          return { value: certification, relationToNext: 'AND' };
        }) || [],
        skills: jobDescription.mandatorySkills && jobDescription.mandatorySkills.map(skill => {
          return { value: skill, relationToNext: 'AND' };
        }) || [],
      });
    }
  }, [jobDescription, isJobOpeningLoaded, autoSuitability.id.length, id]);

  const jobOpeningDetailsRenderMap: { [key in string]: React.ReactNode } = {
    'Job Requisition': <JobRequisitionTab
        jobRequisition={jobRequisition}
        setJobRequisition={setJobRequisition}
        employees={defaultEmployeeUserList}
        defaultHiringManager={hiringManager}
        defaultInternalRecruiter={internalRecruiter}
        setDefaultInternalRecruiter={setInternalRecruiter}
        setDefaultHiringManager={setHiringManager}
    />,
    'Job Description': <JobDescriptionTab
        jobDescription={jobDescription}
        setJobDescription={setJobDescription}
        setJobTitle={setJobTitle}
        jobTitle={jobTitle}
        jobTitleList={jobTitleList}
    />,
    'CV Ingestion': <CVIngestionTab />,
    'Auto Suitability': <AutoEvaluationTab
        key='Auto Suitability'
        autoEvaluation={autoSuitability}
        setAutoEvaluation={setAutoSuitability}
        evaluationType='Auto-Suitability'
    />,
    'Auto Outstanding': <AutoEvaluationTab
        key='Auto Outstanding'
        autoEvaluation={autoOutstanding}
        setAutoEvaluation={setAutoOutstanding}
        evaluationType='Auto-Outstanding'
        importAutoSuitability={() => setAutoOutstanding(autoSuitability)}
    />,
    'Auto Assessments': <AutoAssessmentTab
        autoAssessment={autoAssessment}
        setAutoAssessment={setAutoAssessment}
        jobDescription={jobDescription}
    />,
  };

  const handleStatusChange = (activeLabel: string) => {
    setCurrentTab(activeLabel);
  };

  const renderFormPage = () => {
    if (jobRequisitionLoading || employeeLoading) {
      return <LoadingSpinner />;
    }
    if (currentTab !== null) {
      return jobOpeningDetailsRenderMap[currentTab];
    } else {
      return <></>;
    }
  };

  return (
    <div className='flex gap-6'>
      <ButtonColumn buttons={buttonsList} onStatusChange={handleStatusChange} currentTab={currentTab}/>
      <div
          className='w-full min-h-[80vh] bg-primaryWhite100 shadow-[0_16px_30px_rgba(55,92,170,0.15)] rounded-lg p-10 box-border'
      >
        { renderFormPage() }
      </div>
    </div>
  );
};

export default JobOpeningDetails;
