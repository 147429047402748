import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../../../../../ui/button/button';
import { CustomSelect } from '../../../../../../../ui/custom_react_select/custom_select';
import { AddIcon } from '../../../../../../../ui/icons/icons';
import { TextInput } from '../../../../../../../ui/input/text_input/text_input';
import { SecondaryTypography } from '../../../../../../../ui/typography/typography';
import {
  createAutoBuildQuestions,
  getAutoAssessment,
  setAutoAssessmentSkills,
} from '../../../../../../../utils/api_service/JobOpenings';
import { RootState } from '../../../../../../../utils/redux_store/store';
import { AutoAssessment, JobOpeningSkill } from '../../../../../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../../../../../utils/types/react_select_types';

type AutoBuildProps = {
  autoAssessment: AutoAssessment,
  setAutoAssessment: React.Dispatch<React.SetStateAction<AutoAssessment>>,
  skills: string[],
  jobTitle: string
}

export const AutoBuild = ({
  autoAssessment,
  setAutoAssessment,
  skills,
  jobTitle,
}: AutoBuildProps) => {
  const { id } = useParams<{ id: string }>();
  const [records, setRecords] = useState<JobOpeningSkill[]>(autoAssessment.skills || []);
  const [skillsList, setSkillsList] = useState<string[]>(
    skills.filter(skill => !autoAssessment.skills?.some(iteratedSkill => iteratedSkill.skill === skill)));
  const [generatingQuestions, setGeneratingQuestions] = useState<boolean>(false);
  const levels = useSelector((state: RootState) => state.skillLevels.levelList);

  const questionTypesList: SelectOptions[] = [
    {
      value: 'Modern',
      label: 'Modern',
    },
    {
      value: 'Classic',
      label: 'Classic',
    },
  ];

  const pollJobStatus = useCallback(() => {
    let isCancelled = false;

    const poll = () => {
      getAutoAssessment(id)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (!isCancelled) {
            if (data.status === 'Generated Questions for all skills' || data.status === null) {
              setGeneratingQuestions(false);
              toast.success('Questions Generated Successfully', { position: 'bottom-center' });
            } else {
              setTimeout(poll, 5000);
            }
          }
        })
        .catch(error => {
          if (!isCancelled) {
            toast.error(`An error occurred while polling the job status - ${error}`, { position: 'bottom-center' });
          }
        });
    };

    poll();

    return () => {
      isCancelled = true;
    };
  }, [id]);

  useEffect(() => {
    const fetchAutoAssessment = async () => {
      try {
        const response = await getAutoAssessment(id);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status && data.status === 'Generated Questions for all skills' || data.status === null) {
          setGeneratingQuestions(false);
        } else {
          setGeneratingQuestions(true);
          pollJobStatus();
        }
      } catch (error) {
        toast.error(error, { position: 'bottom-center' });
      }
    };

    fetchAutoAssessment();
  }, [id, pollJobStatus]);

  const addNewSkillRow = () => {
    setRecords(prevRecords => [
      ...prevRecords,
      {
        skill: '',
        level: '',
        numberOfQuestions: 0,
        points: 0,
        time: 0,
        questionType: '',
      },
    ]);
  };

  const saveSkills = () => {
    const recordsWithoutTemp = records.map(({ temp, ...record }) => record);
    setAutoAssessmentSkills(id, recordsWithoutTemp).then((response) => {
      if (response.ok) {
        toast.success('Skills Saved Successfully', { position: 'bottom-center' });
        setAutoAssessment({
          ...autoAssessment,
          skills: recordsWithoutTemp,
        });
      } else {
        toast.error('Error Saving Skills', { position: 'bottom-center' });
      }
    });
  };

  const handleInputChange = (index: number, prop: keyof JobOpeningSkill) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecords(prevRecords => prevRecords.map((record, i) => i === index ? { ...record, [prop]: event.target.value } : record));
  };

  const handleSelectChange = (index: number, prop: keyof JobOpeningSkill) => (selectedOption: SelectOptions) => {
    setRecords(prevRecords => prevRecords.map((record, i) => i === index ? { ...record, [prop]: selectedOption.value } : record));
  };

  const handleAddSkill = (skill: string) => {
    setRecords(prevRecords => [
      ...prevRecords,
      {
        skill: skill,
        level: '',
        numberOfQuestions: 0,
        points: 0,
        time: 0,
        questionType: '',
        temp: true,
      },
    ]);
    setSkillsList(prevSkills => prevSkills.filter(s => s !== skill));
  };

  const handleRemoveSkill = (index: number) => {
    if (records[index].temp) {
      const skillToRemove = records[index];
      setRecords(prevRecords => prevRecords.filter((_, i) => i !== index));
      setSkillsList(prevSkills => [...prevSkills, skillToRemove.skill]);
    } else {
      setRecords(prevRecords => prevRecords.filter((_, i) => i !== index));
    }
  };

  const generateQuestions = () => {
    createAutoBuildQuestions(autoAssessment.id, jobTitle)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setGeneratingQuestions(true);
        pollJobStatus();
      })
      .catch(error => {
        toast.error(`Error Generating Questions - ${error}`, { position: 'bottom-center' });
      });
  };

  const ThreeDotLoader = () => (
    <div className='flex space-x-2'>
      <div className='w-2 h-2 bg-white rounded-full animate-bounce'></div>
      <div className='w-2 h-2 bg-white rounded-full animate-bounce200'></div>
      <div className='w-2 h-2 bg-white rounded-full animate-bounce400'></div>
    </div>
  );

  return (
    <div className='w-full p-5 flex flex-row'>
      <div className='w-9/12'>
        { records.length > 0 ? (
          <table className='table-auto'>
            <thead>
              <tr>
                <th className='text-left text-gray-600 p-2.5 font-medium'><input type='checkbox' id={'checkbox'}/>
                </th>
                <th className='text-left text-gray-600 p-2.5 font-medium w-1/6'>Skill</th>
                <th className='text-left text-gray-600 p-2.5 font-medium w-1/6'>Level</th>
                <th className='text-left text-gray-600 p-2.5 font-medium'># of Q</th>
                <th className='text-left text-gray-600 p-2.5 font-medium'>Points</th>
                <th className='text-left text-gray-600 p-2.5 font-medium'>Time</th>
                <th className='text-left text-gray-600 p-2.5 font-medium w-1/6'>Q Type</th>
              </tr>
            </thead>
            <tbody>
              { records.map((record, index) => (
                <tr key={index}>
                  <td><input type='checkbox' id={`checkbox-${index}`}/></td>
                  <td><TextInput type='text' name='skill' onChange={handleInputChange(index, 'skill')}
                      value={record.skill}/></td>
                  <td><CustomSelect name='level' options={levels} onChange={handleSelectChange(index, 'level')}
                      value={levels.find(option => option.value === record.level)}/></td>
                  <td><TextInput type='number' name='numberOfQuestions'
                      onChange={handleInputChange(index, 'numberOfQuestions')}
                      value={record.numberOfQuestions.toString()}/></td>
                  <td><TextInput type='number' name='points' onChange={handleInputChange(index, 'points')}
                      value={record.points.toString()}/></td>
                  <td><TextInput type='number' name='time' onChange={handleInputChange(index, 'time')}
                      value={record.time.toString()}/></td>
                  <td><CustomSelect name='questionType' options={questionTypesList}
                      onChange={handleSelectChange(index, 'questionType')}
                      value={questionTypesList.find(option => option.value === record.questionType)}/>
                  </td>
                  <td><Button variant='danger' size='small'
                      onClick={() => handleRemoveSkill(index)}>Remove</Button></td>
                </tr>
              )) }
            </tbody>
          </table>
        ) : (
          <div className='flex items-center justify-center h-2/3 w-full'>
            <div className='text-center text-xl text-gray-600'>
              No skills created yet
            </div>
          </div>
        ) }
        <div
            className='flex flex-row'
        >
          <Button
              variant='primary'
              size='medium'
              onClick={addNewSkillRow}
          >
            Add Skill
          </Button>
          <Button
              variant='success'
              size='medium'
              onClick={saveSkills}
          >
            Save Skills
          </Button>
        </div>
        { skillsList.length > 0 &&
        <SecondaryTypography.Small
            className='text-gray-600 p-2.5'
            fontWeight='semi-bold'
        >
          Add Skills from JD
        </SecondaryTypography.Small>
        }
        <div
            className='flex flex-row'
        >
          { skillsList.map(skill => (
            <Button
                key={skill}
                variant='secondary'
                size='small'
                icon={<AddIcon/>}
                onClick={() => handleAddSkill(skill)}
            >
              { skill }
            </Button>
          )) }
        </div>
      </div>
      <div className='w-3/12 p-5'>
        <TextInput
            label='Job Title'
            type='text'
            onChange={() => {
            }}
            value={jobTitle && JSON.parse(jobTitle)?.jobTitle || ''}
            name='jobTitle'
        />
        <Button
            variant='primary'
            size='medium'
            disabled={generatingQuestions || records.length === 0}
            onClick={generateQuestions}
        >
          { generatingQuestions ? (
            <div className='flex flex-row items-center'>
              <ThreeDotLoader/>&nbsp;
              <span>Generating Questions...</span>
            </div>
          ) : (
            'Create List of Questions'
          ) }
        </Button>
      </div>
    </div>
  );
};
