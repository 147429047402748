import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DraggableQuestions } from '../../../../../ui/draggable_questions/draggable_questions';
import { createAutoAssessment } from '../../../../../utils/api_service/JobOpenings';
import { RootState } from '../../../../../utils/redux_store/store';
import { AutoAssessment, JobDescription } from '../../../../../utils/types/JobOpenings';
import { SelectOptions } from '../../../../../utils/types/react_select_types';
import { Tabs } from '../../../../assessment/view/tabs/tabs';
import { Settings } from '../../../../assessment/view/view_assessment/settings/settings';
import { AutoBuild } from './TabContent/AutoBuild';
import { InvitationTemplate } from './TabContent/InvitationTemplate';

type AutoAssessmentProps = {
  autoAssessment: AutoAssessment,
  setAutoAssessment: React.Dispatch<React.SetStateAction<AutoAssessment>>,
  jobDescription: JobDescription,
}

export const AutoAssessmentTab = ({
  autoAssessment,
  setAutoAssessment,
  jobDescription,
}: AutoAssessmentProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabTitles = ['Quick Build', 'List of Questions', 'Permissions and Settings', 'Invitation Template'];
  const skills = useSelector((state: RootState) => state.skills);
  const levels = useSelector((state: RootState) => state.skillLevels);
  const jobTitles = useSelector((state: RootState) => state.jobTitles);
  const subSkills = useSelector((state: RootState) => state.subSkills);
  const [skillList, setSkillList] = useState<SelectOptions[]>(skills.skillsList);

  useEffect(() => {
    if (autoAssessment.id.length === 0) {
      createAutoAssessment(autoAssessment).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error Fetching Job Requisition');
        }
      }).then((data) => {
        setAutoAssessment(data);
      });
    }
  }, []);

  const updateEmailTemplateState = (updatedTemplate: string) => {
    setAutoAssessment({
      ...autoAssessment,
      emailTemplate: updatedTemplate,
    });
  };

  const renderTabContent = () => {
    if (activeTab === 0) {
      return <AutoBuild
          autoAssessment={autoAssessment}
          setAutoAssessment={setAutoAssessment}
          skills={[...(jobDescription?.desirableSkills || []), ...(jobDescription?.mandatorySkills || [])]}
          jobTitle={JSON.stringify(jobDescription?.jobTitle)}
      />;
    } else if (activeTab === 1) {
      return (
        <div
            style={{ margin: '20px 0' }}
        >
          <DraggableQuestions
              assessmentId={autoAssessment.assessmentId}
              skillList={skillList}
              levelList={levels.levelList}
              jobTitleList={jobTitles.jobTitlesList}
              subSkillList={subSkills.subSkillsList}
              setSkillList={setSkillList}
          />
        </div>
      );
    } else if (activeTab === 2) {
      return <Settings assessmentType={'candidate'} assessmentId={autoAssessment.assessmentId}/>;
    } else if (activeTab === 3) {
      return <InvitationTemplate emailTemplate={autoAssessment.emailTemplate} updateEmailTemplateState={updateEmailTemplateState}/>;
    }
  };

  return (
    <div>
      <Tabs
          tabTitles={tabTitles}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
      />
      { renderTabContent() }
    </div>
  );
};
